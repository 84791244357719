import React from "react";
import {navigate} from 'gatsby';

import DrvCover from '../../Shared/DrvCover/DrvCover';
import { DrvLinkPath } from "../../Shared/DrvLink/DrvLinkPath";

import { Container, NewsDateContainer,NewsDate, NewsInfo, LinkToNews, LinkToDRV, NewsInfoText ,NewsContent } from './NewsDetails.styles';

import NewsImage from "../../../images/OurCompany/News/news.jpeg";

const NewsDetails = ({data}) => {
    let breadcrumbs = [{text: 'Drivosity', link: '/', isLink: true}, {text: 'News', link: '/news', isLink: true}, {text: data?.title || '', link: '#', isLink: false}];


    return(
        <>
            <DrvCover
                title='NEWS'
                description=''
                color='#ffffff'
                image={NewsImage}
            />
            <Container>
                <div className="container">
                    <div className="mb-10 mt-10">
                        <DrvLinkPath items={breadcrumbs}/>
                    </div>
                    <div className="drv-title">
                        {data?.title}
                    </div>
                    <NewsDateContainer>
                        <NewsDate>
                                <div className="text-2xl font-semibold">
                                    {
                                        new Date(data?.data.date).toLocaleString("en-US", { day : '2-digit', timeZone: 'UTC' })
                                    }
                                </div>
                                <div>
                                    {
                                        new Date (data?.data.date).toLocaleString('default', { month: 'short', timeZone: 'UTC' })
                                    }
                                </div>
                        </NewsDate>
                        <NewsInfo>
                            <NewsInfoText>
                                Posted by
                            </NewsInfoText>
                            <LinkToDRV to="/">
                                    Drivosity
                            </LinkToDRV>
                            <NewsInfoText>
                                Category:
                            </NewsInfoText>
                            <LinkToNews to="/news">
                                News
                            </LinkToNews>
                        </NewsInfo>
                    </NewsDateContainer>
                    <NewsContent>
                        {data?.data.content}
                    </NewsContent>
                </div>
            </Container>
        </>
    )
}

export default NewsDetails;
