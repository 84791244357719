import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import NewsDetails from "../components/OurCompany/News/NewsDetails";
import { useLocation } from "@reach/router";

import Favicon from "../images/Favicon.png";

import {NewsData} from '../components/OurCompany/News/News.data'
import { navigate } from "gatsby";

const NewsDetailsPage = ({ params }) => {
    const location = useLocation();
    const [data, setData] = useState(null);

    useEffect(() => {
        const pathParts = location.pathname.split("/news-details/"); 
        const id = pathParts[1]?.toLowerCase();
        if (id) {
            const foundNews = NewsData.find(item => 
                item.title.replace(/[’:?]/g, "").replace(/ /g, "-").toLowerCase() === id
            );
            if(!foundNews){
                navigate('/404')
            }
            setData(foundNews || null);
        }
    }, [location.pathname]);
    

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>News - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                    content="Stay updated with the latest news in the Last-Mile Delivery industry. Gain insights on first-party delivery and third-party delivery, safety, productivity, and industry innovations. Learn how Drivosity is leading the way in last-mile delivery solutions. Read more today."/>
                <meta name="description" content={data?.data?.metadesc || ''}/>
            </Helmet>
            {
                data !== null ?
                (
                    <Layout component={<NewsDetails data={data} />}/>
                ) :
                (
                    <div>loading...</div>
                )
            
            }
        </>
    )
}

export default NewsDetailsPage;
